@media (min-width: 767px ) {
    .navItem{
        padding-right: 20px;
    }
}

.navItem a {
    display: inline-flex;
    font-size: 19px;
    padding: 0.5rem;
    text-decoration: none;
}

.navItem a:hover {
    color: black;
    transition: .2s ease-in-out;
    transform: scale(1.1);
}

.active {
    color: black;
    cursor: default;
    transform: none;
}

a.active:hover {
    cursor: default;
    transform: none;
}

.inActive {
    color: #7a7a7a;
}

.Navbar {
    display: flex;
}

.main {
    padding: 0 0 20px;
}

@media (max-width: 767px){
    .main{
        margin-left: 0;
        display: flex;
        justify-content: center;
        margin-right: 0;
    }
    .navItem a{
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 17px;
        text-align: center;
    }

    .Navbar{
        margin-left: 0;
    }
    .contacte{
        padding-right: 0;
        margin-left: 10px;
    }
}
