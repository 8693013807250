.contactMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.logInfo {
    padding: 20px;
}

.mainContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2.5rem;
    width: 100%;
}

.iframeWrap {
    width: 100%;
}

.iframeContainer {
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
}

iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@media (min-width: 1440px ) {
    .mainContent {
        justify-content: space-between;
    }

    .iframeWrap {
        width: 60%;
    }
}

.logInfo img {
    margin: 0 auto;
}

.contactInfo {
    color: #444444;
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.4;
    text-align: center;
    margin: 2.5rem 0;
}

@media (max-width: 770px ) {
    .contactName {
        font-size: 55px;
    }

    .mainContent {
        display: block;
    }
}

@media (max-width: 767px) {
    .contactName {
        font-size: 35px;
        padding-top: 5%;
    }

    .mainContent {
        display: block;
        margin-top: 10%;
    }
}

@media (max-width: 320px) {
    .mainContent {
        margin-top: 10%;
    }
    .contactMain{
        margin-left: 10%;
    }
}
