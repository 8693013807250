* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Roboto';
    font-size: 100%;
}

.App {
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 1.5;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media (min-width: 768px)  {
    .App {
        flex-direction: row;
    }
}

.header {
    height: 60px;
    display: flex;
}

.main {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 40px 16px 20px;
}

@media (min-width: 768px)  {
    .main {
        font-size: 20px;
        padding: 40px;
        max-width: 70rem;
        margin: 0 auto;
    }
}

@media (min-width: 1440px)  {
    .main {
        padding: 40px 80px;
        max-width: 90rem;
    }
}

.line-1 {
    border-left: 2px solid #efefef;
    height: 100vh;
    position: absolute;
    left: 14%;
    top: 0;
}

.line-2 {
    border-left: 2px solid #efefef;;
    height: 100vh;
    position: absolute;
    left: 28%;
    top: 0;
}

.line-3 {
    border-left: 2px solid #efefef;
    height: 100vh;
    position: absolute;
    left: 42%;
    top: 0;
}

.line-4 {
    border-left: 2px solid #efefef;
    height: 100vh;
    position: absolute;
    left: 56%;
    top: 0;
}

.line-5 {
    border-left: 2px solid #efefef;
    height: 100vh;
    position: absolute;
    left: 70%;
    top: 0;
}

.line-6 {
    border-left: 2px solid #efefef;
    height: 100vh;
    position: absolute;
    left: 84%;
    top: 0;
}
.line-1Service {
    border-left: 2px solid #efefef;
    height: 100vmax;
    position: absolute;
    left: 14%;
    top: 0;
}

.line-2Service {
    border-left: 2px solid #efefef;;
    height: 100vmax;
    position: absolute;
    left: 28%;
    top: 0;
}

.line-3Service {
    border-left: 2px solid #efefef;
    height: 100vmax;
    position: absolute;
    left: 42%;
    top: 0;
}

.line-4Service {
    border-left: 2px solid #efefef;
    height: 100vmax;
    position: absolute;
    left: 56%;
    top: 0;
}

.line-5Service {
    border-left: 2px solid #efefef;
    height: 100vmax;
    position: absolute;
    left: 70%;
    top: 0;
}

.line-6Service {
    border-left: 2px solid #efefef;
    height: 100vmax;
    position: absolute;
    left: 84%;
    top: 0;
}
.line-1About {
    border-left: 2px solid #efefef;
    height: 109vh;
    position: absolute;
    left: 14%;
    top: 0;
}

.line-2About {
    border-left: 2px solid #efefef;;
    height: 109vh;
    position: absolute;
    left: 28%;
    top: 0;
}

.line-3About {
    border-left: 2px solid #efefef;
    height: 109vh;
    position: absolute;
    left: 42%;
    top: 0;
}

.line-4About {
    border-left: 2px solid #efefef;
    height: 109vh;
    position: absolute;
    left: 56%;
    top: 0;
}

.line-5About {
    border-left: 2px solid #efefef;
    height: 109vh;
    position: absolute;
    left: 70%;
    top: 0;
}

.line-6About {
    border-left: 2px solid #efefef;
    height: 109vh;
    position: absolute;
    left: 84%;
    top: 0;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .hide-on-mobile {
        display: none;
    }
}

.page-title {
    color: #444444;
    margin-bottom: 2rem;
    text-align: center;
}

@media (min-width: 768px) {
    .page-title {
        text-align: left;
        font-size: 50px;
    }
}

p {
    text-indent: 2rem;
}

a {
    color: #444444;
    text-decoration: underline;
    font-weight: normal;
    transition: all 0.3s ease-in-out;
}

a:hover {
   text-shadow: 0.5px 0 0 #444444;
}
