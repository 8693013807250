.footer {
    display: flex;
    flex: 0 0 90px;
    background-color: #444444;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footerSercive {
    display: flex;
    flex: 0 0 90px;
    background-color: #444444;
    flex-direction: column;
    align-items: center;
}
.langChangeDesktop{
    display: block;
    margin-top: 35px;
}
.langChangeMobile{
    display: none;
}
.footerAbout {
    display: flex;
    flex: 0 0 90px;
    background-color: #444444;
    flex-direction: column;
    align-items: center;
}

.icons {
    display: flex;
    flex-direction: column;
    margin-top: 57px;
    margin-bottom: 58px;
}

.email {
    margin-top: auto;
}

.email img {
    width: 65px;
}

.emailService {
    position: fixed;
    bottom: 5px;
}
.emailJos{
    margin-bottom: unset;
}
.emailService img {
    width: 65px;
}

.emailAbout {
    position: fixed;
    bottom: 5px;
}

.emailAbout img {
    width: 65px;
}

.fb {
    margin-top: 57px;
    margin-left: 9px;
}

.fb img {
    width: 50px;
}

.social {
    transition: .2s ease-in-out;
}

.social:hover {
    transform: scale(1.2);
    text-decoration: none;
}

.btn {
    background: transparent;
    color: white;
    border: 0;
}

.btn:focus {
    color: #89888A;
}

.langChange {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.line {
    display: flex;
    margin: 4px 0;
    border: 1px solid white;
}


@media (max-width: 767px) {
    .langChangeDesktop{
        display: none;
    }
    .langChangeMobile{
        display: flex;
    }
    .footer {
        flex: 0 1 65px;
        height: unset;
        justify-content: space-around;
        flex-direction: unset;
    }

    .footerAbout {
        justify-content: space-around;
        flex-direction: unset;
    }

    .footerSercive {
        flex-direction: unset;
        justify-content: space-around;
    }

    .fb {
        margin-top: 0;
        margin-left: 0;
    }

    .email {
        margin-top: 0;
        margin-bottom: 0;
    }
    .langChange{
        flex-direction: unset;
        margin-left: 0;
    }
    .line{
        margin: -5px 7px;
    }
    .emailAbout{
        position: unset;
        top: 0;
    }
    .emailService{
        position: unset;
        top: 0;
    }
}

@media (max-width: 320px) {
    .footer {
        width: 100vw;
        height: unset;
    }

    .footerAbout {
        width: 100vw;
    }

    .footerSercive {
        width: 102vw;
    }
}
