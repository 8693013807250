.header {
}

.Navbar {
    display: flex;
    margin-left: auto;
    padding-right: 20px;
}

@media (max-width: 767px) {
    .logo {
        font-family: Libre Baskerville;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 4%;
        justify-content: center;
    }
}
