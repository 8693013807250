.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutInfo {
    display: flex;
}

.aboutInfoItem {
    max-width: 480px;
    color: #444444;
    font-size: 31px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.aboutInfoItem span {
    color: #8d7f72;
}

.aboutInfoDescription {
    color: #000000;
    font-size: 22px;
    text-align: left;
    padding: 0 1rem;
}

.footerBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.copyRight {
    display: flex;
    margin-top: 10%;
    color: #7a7a7a;
    font-family: Helvetica;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 4%;
}

@media (max-width: 770px) {
    .aboutInfoItem {
        font-size: 25px;
    }

    .logo {
        width: 230px;
        height: 230px;
    }

    .copyRight {
        font-size: 17px;
    }

    .aboutName {
        font-size: 55px;
        margin-top: 5%;
    }
}

@media (max-width: 767px) {
    .aboutName {
        font-size: 50px;
    }

    .aboutInfo {
        display: block;
    }

    .aboutInfoItem {
        font-size: 25px;
        margin-bottom: 5%;
    }

    .aboutInfoDescription {
        margin-left: 0;
        font-size: 17px;
    }

    .copyRight {
        font-size: 15px;
    }

    .logo {
        width: 200px;
        height: 200px;
    }
}
