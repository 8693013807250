.service-item {
    padding: 2rem 1.25rem;
    background-color: #545454;
    border-radius: 1rem;
    box-shadow: 5px 5px 15px 5px #6e6e6e;
    color: #fff;
    margin-bottom: 2.5rem;
}

.service-title {
    font-size: 18px;
    letter-spacing: 1.2px;
    line-height: 1.3;
    padding-right: 6rem;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 5rem 100%;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}

.service-more-info {
    white-space: pre-line;
}

.service-alarm .service-title {
    background-image: url('../Svg/alarm.svg');
}

.service-securitate .service-title {
    background-image: url('../Svg/securitate.svg');
}

.service-pazaFizica .service-title {
    background-image: url('../Svg/pazaFizica.svg');
}

.service-transport .service-title {
    background-image: url('../Svg/transport.svg');
}

.service-corp .service-title {
    background-image: url('../Svg/corp.svg');
}

.service-btn {
    background-color: #444;
    padding: 1rem 2rem;
    border: 0;
    border-radius: 2rem;
    margin-top: 1.5rem;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    transition: all 0.3s ease-in-out;
}

.service-btn:hover {
    transform: scale(1.04);
}

@media (min-width: 768px) {
    .service-item {
        color: #444;
        background: none;
        box-shadow: none;
    }
}

@media (max-width: 768px) {
    .service-btn {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 2rem;
        font-size: 0;
    }

    .is-up {
        background-image: url('../Svg/arrow-up.svg');
    }

    .is-down {
        background-image: url('../Svg/arrow-down.svg');
    }

    .service-title {
        min-height: 4rem;
    }
}
