.text {
    font-family: 'Roboto';
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    animation-name: textAppear;
    animation-duration: .5s;
}

.home {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 auto;
}

.copyRight {
    margin-top: auto;
    color: #7a7a7a;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
}

.description {
    color: #7a7a7a;
    font-size: 28px;
    text-align: left;
    padding-bottom: 19px;
    animation-name: textAppear;
    animation-duration: .5s;
}

.mainSevice {
    display: flex;
    animation-name: arrowAppear;
    animation-duration: 1s;
}

.service {
    color: #000000;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    padding-left: 13px;
}

.service:hover {
    transition: .2s ease-in;
    transform: translateX(20px);
}

.main {
    display: flex;
}

.mainSeviceMobile{
    display: none;
}

@keyframes textAppear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes arrowAppear {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@media (max-width: 767px ) {
    .text {
        font-size: 29px;
        text-align: center;
    }

    .description {
        font-size: 13px;
        text-align: center;
        display: none;
    }

    .service {
        font-size: 15px;
        line-height: 24px;

    }

    .homeArrow {
        width: 21px;
        height: 24px;
    }

    .mainSevice {
        display: none;
    }
    .mainSeviceMobile{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
    }

    .serviceMobileInfo{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 34px;
        background: #C4C4C4;
        mix-blend-mode: darken;
        border-radius: 30px;
    }

    .serviceMobileInfo a{
        font-size: 16px;
        letter-spacing: 1.2px;
        text-align: center;
        color: #000000;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-flex;
        padding: 16px 35px;
    }
    .usgCopyRight {
        display: flex;
        justify-content: center;
        padding-top: 40px;
    }

    .copyRight {
        font-size: 13px;
        margin-left: 0;
    }

    .logoWrap {
        width: 75%;
    }
}

.logoWrap {
    display: flex;
    padding: 20px;
    flex: 1 1 auto;
    align-items: center;
}

.logoWrap img {
    margin: 0 auto;
    width: 100%;
}

@media (min-width: 768px ) {
    .home {
        align-items: center;
        flex-direction: row;
    }

    .text {
        font-size: 45px;
        margin-bottom: 40px;
    }

    .description {
        font-size: 17px;
    }

    .logoWrap {
        flex: 1 0 40%;
        padding: 20px 20px 20px 40px;
    }
}

@media (min-width: 1440px) {
    .text {
        font-size: 75px;
    }

    .logoWrap {
        margin-right: -80px;
        padding-right: 40px;
    }
}
