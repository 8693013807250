.serviciiNoastre {
    color: #444444;
    font-size: 68px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.footerBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copyRight {
    color: #7a7a7a;
    margin-top: 2rem;
    text-transform: uppercase;
}
